// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  SENTRY_ORG:"rzp",
  SENTRY_PROJECT:"walletbuddy_axis_new",
  SENTRY_AUTH_TOKEN:"sntrys_eyJpYXQiOjE3MTc0MDYyOTYuNjA0NzUsInVybCI6Imh0dHBzOi8vc2VudHJ5LmlvIiwicmVnaW9uX3VybCI6Imh0dHBzOi8vdXMuc2VudHJ5LmlvIiwib3JnIjoicnpwIn0",
  sentryDsn: "https://8dbd42db54e3d1ef476cfb5264abee07@o515678.ingest.us.sentry.io/4507351240540160",
  apiUrlBsV1: "https://sandbox-api.poshvine.com/bs/v1/",
  apiUrlCsV1: "https://sandbox-api.poshvine.com/cs/v1/",
  apiUrlPaV1: "https://sandbox-api.poshvine.com/pa/v1/",
  apiUrlPsV1: "https://sandbox-api.poshvine.com/ps/v1/",
  // apiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  // sandboxUrl:'https://rewardshub-stage-api.poshvine.com/',
  apiUrl: "https://rewards-api.poshvine.com/api/v1/",
  sandboxUrl: "https://rewards-api.poshvine.com/",
  offersLink: "https://sandbox-offers.extraordinaryweekends.com/",
  corePath: "https://sandbox-offerservice.poshvine.com/core_service/v1",
  offerPath: "https://sandbox-offerservice.poshvine.com/offer_service/v1",
  clientId: "8719fb72-1989-43ad-a039-1b55a2e0d1fa",
  projectId: "2c83fb67-68d7-46d0-9b2d-81cdf903aadc",
  countryId: "ffd8ff9f-5631-42e0-92a1-2a74d3491858",
};